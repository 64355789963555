import React, { Component } from 'react';
import { connect } from 'react-redux';
import './forms.css';
import ProfileInformation from './profile_information/profile_information'
import Education from './education/education'
import Experience from './experience/experience'
import Extra_Curricular from './extra-curr/extra-curr'
import Language from './language/language';
import Skills from './skills/skills';


class Multi_stepform extends Component {



    render() {
        const multiple_form = <div >
            {(this.props.current_state === 'proinfo') ?
                <ProfileInformation /> : null
            }
            {(this.props.current_state === 'educ') ?
                <Education /> : null
            }
            {(this.props.current_state === 'expe') ?
                <Experience /> : null
            }
            {(this.props.current_state === 'ex_curr') ?
                <Extra_Curricular /> : null
            }
            {(this.props.current_state === 'language') ?
                <Language /> : null
            }
            {(this.props.current_state === 'skills') ?
                <Skills /> : null
            }
        </div>

        return (
            <main className="formdiv">
                {multiple_form}
            </main>
        );
    }
}


const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        current_state: state.common_reducer.current_state
    }
}

export default connect(mapStateToProps)(Multi_stepform);