import html2pdf from 'html2pdf.js';
import fliprLogo from './fliprlogo.js';

let script= () => {
	let page_height, footer_space= 32, page_number= 1, footer_size= 25, break_point= 0, rem_elements= [], marker_offset= parseInt(getComputedStyle(document.body).marginTop);


	let handle_clone= clone => {
		rem_elements.forEach(ele => ele.remove());
	}

	let add_marker= (dy, col= "red") => {
		let flag= document.createElement("div");
		flag.classList.add("break_indicator");
		flag.setAttribute("style", `top:${dy}px; background-color: ${col}`);
		document.body.appendChild(flag);   
	} 

	let outer_height= el => {
		var height = el.offsetHeight;
		var style = getComputedStyle(el);
		height += parseInt(style.marginTop) + parseInt(style.marginBottom);
		return height;
	}	


	let manage_page_breaks_v3= (cloned_element) => {

		let elements_array= [...cloned_element.getElementById('container').childNodes];
		let remaining_blocks;
		let relative_page_height= page_height - 70;

		elements_array.reduce((accumulator, currentValue, currentIndex, array) => {
			//add_marker(page_height * page_number);
			//add_marker(accumulator + marker_offset, "grey");

			if(accumulator + outer_height(currentValue) < relative_page_height * page_number)
			{
				return outer_height(currentValue) + accumulator;
			}
			else
			{
				//console.log(`page ${page_number} break element: `, elements_array[currentIndex]);
				//console.log(`page ${page_number} elements: `, elements_array.slice(0, currentIndex));

				let break_point= accumulator;
				let upper_height= elements_array.slice(0, currentIndex).map(itm => outer_height(itm)).reduce((accu, curr) => accu + curr);
				let break_element= elements_array[currentIndex - 1].gatName === 'H2'? elements_array[currentIndex - 2] : elements_array[currentIndex]

				//console.log(elements_array.slice(0, currentIndex));
				//console.log(elements_array.slice(0, currentIndex).map(itm => outer_height(itm)));
				//console.log(upper_height);
				//add_marker(upper_height, "yellow");

				let head= cloned_element.createElement("div");
				head.classList.add("break");
				rem_elements.push(head);
				break_element.parentElement.insertBefore(head, break_element);
				
				let foot= cloned_element.createElement("div");
				foot.innerHTML= `<span>Made with</span> <img src= ${fliprLogo} alt="Flipr" class= "flipr_logo"/>`;
				foot.classList.add("page_footer");
				foot.style.marginTop= (relative_page_height * page_number) - upper_height + "px"; 
				rem_elements.push(foot);
				head.parentElement.insertBefore(foot, head);

				page_number++;
				remaining_blocks= elements_array.slice(currentIndex, elements_array.length);

				return outer_height(currentValue) + accumulator;
			}
		}, 0);

		if(remaining_blocks === undefined)
		{
			remaining_blocks= elements_array;
			//console.log(remaining_blocks);
		}

		//console.log("remaining blocks on final page:", remaining_blocks);
		let ramaining_height= remaining_blocks.map(itm => outer_height(itm)).reduce((accumulator, currentValue) => accumulator + currentValue);
		let leg_room= (footer_space + footer_size) * page_number;
		let difference= page_height - leg_room - ramaining_height;

		//add_marker(ramaining_height + page_height , "green");

		let foot= cloned_element.createElement("div");
		foot.innerHTML= `<span>Made with</span> <img src= ${fliprLogo} alt="Flipr" class= "flipr_logo"/>`;
		foot.classList.add("page_footer");
		foot.style.marginTop= difference + "px"; 
		rem_elements.push(foot);
		elements_array[elements_array.length - 1].parentElement.appendChild(foot);

		//console.log(page_height, leg_room, ramaining_height, page_height - leg_room - ramaining_height);
		//add_marker(page_height, leg_room, ramaining_height , "green");

	}


	var element = document.getElementById('container');
	var opt = {
		margin: 0,
		filename: 'CV.pdf',
		image: 
		{ 
			type: 'jpeg', quality: 0.98 
		},
		html2canvas: 
		{ 
			allowTaint: true,
			useCORS: true,
			onclone: handle_clone
		},
		jsPDF:  
		{
			format: "A4", 
			orientation: 'portrait' 
		},
		mode: ['css']
	}


	let PDF= html2pdf();
	PDF= PDF.set(opt).from(element)
	PDF.then(() => {
		//console.log(PDF.prop);
		page_height= PDF.prop.pageSize.inner.px.height;
		//console.log("page_height: ", page_height)

		manage_page_breaks_v3(document);
		PDF.save();
	});
}

export default script;