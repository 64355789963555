import React, { Component } from 'react';
import { connect } from 'react-redux';
import Html2pdf from './html2pdf';
import classes from './pdf_resume.module.css'
import Footer from './../UI/footer/footer'
import resume1 from './../../img/resume-format-1-2-x.png'
import resume2 from './../../img/resume-format-2-2-x.png'

//Route order is very important
class Pdf_resume extends Component {

    gotohome = () => {
        this.props.history.push(`/student_form`)
    }

    download_free_resume = () => {
        this.props.onLoader(true)
        let data = {
            profile_info: this.props.profile_info,
            extra_curricular: [...this.props.extra_curricular],
            experience: [...this.props.experience],
            education: this.props.education,
            skills: [...this.props.skills],
            languages: [...this.props.languages]
        }
        // console.log(data);
        fetch('https://backend-pdf.flipr.ai/free', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'corse': true
            },
            body: JSON.stringify(data)
        })


            .then(res => {
                this.props.onLoader(false)
                res.blob().then(obj => window.open(URL.createObjectURL(obj)))
            })
            .catch(err => this.props.onPop_up_message('Something went wrong'))


    }


    render() {
        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.resume_header}>
                        <h1 className={classes.download_resume_header}>Download Resume</h1>
                        <button onClick={this.gotohome} className={[classes.btn_home, 'btn', 'btn-primary', 'btn-submit'].join(' ')}>Home</button>
                    </div>
                    <div className={classes.resumeshow}>
                        {/* <div className={classes.download_btn}>
                            <img src={resume1} />
                            <div className={classes.download_btn_div}>
                                <span>Free</span>
                                <button className="btn btn-primary btn-submit">Download</button>
                            </div>
                        </div> */}
                        <div className={classes.download_btn}>
                            <img className={classes.image_resume} src={resume2} />
                            <div className={classes.download_btn_div}>
                                <span>Free</span>
                                <button onClick={this.download_free_resume} className="btn btn-primary btn-submit">Download</button>
                            </div>
                        </div>
                        {/* <div className={classes.download_btn}>
                            <img src={resume1} />
                            <div className={classes.download_btn_div}>
                                <span>Paid</span>
                                <button className="btn btn-primary btn-submit">Download</button>
                            </div>
                        </div> */}
                    </div>

                </ div>
                <div style={{ marginTop: '100px' }}>
                    <Footer />
                </div>
            </div>

        );
    }

}


const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userid: state.common_reducer.userId,
        user_databaseId: state.common_reducer.user_databaseId,
        profile_info: state.profile_info_reducers.profile_info,
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item, //for componenedidupdate
        experience: state.experience_reducer.experience,
        experience_item: state.experience_reducer.experience_item, //for componenedidupdate
        education: state.education_reducer.education,
        skills: state.skills_reducer.skills,
        languages: state.languages_reducer.languages,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthState: ((token, userId, user_databaseId) => dispatch({
            type: 'authcheck', value: { token: token, userId: userId, user_databaseId: user_databaseId }
        })),
        onLoader: ((loader) => dispatch({
            type: 'loader', value: {
                loader: loader
            }
        })),
        onPop_up_message: ((pop_up_message) => dispatch({
            type: 'pop_up_message', value: {
                pop_up_message: pop_up_message
            }
        })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pdf_resume);
