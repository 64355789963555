import React from 'react';
import H2pdf from './main_content.js';
import './style.css';
import script from './javascript.js';
import download_icon from './download.svg';
import { connect } from 'react-redux';

class Html2Pdf extends React.Component {

	state = {
		data: null
	};


	render() {
		const data = {
			profile_info: this.props.profile_info,
			education: this.props.education,
			experience: [...this.props.experience],
			skills: [...this.props.skills],
			languages: [...this.props.languages],
			extra_curricular: [...this.props.extra_curricular]
		}

		let resume = <H2pdf data={data} />

		return (<Aux>
			<button onClick={script} id="print"><img src={download_icon} alt="download PDF" /></button>
			{resume}
		</Aux>)
	}

}

let Aux = props => props.children;

const mapStateToProps = state => {
	return {
		token: state.common_reducer.token,
		profile_info: state.profile_info_reducers.profile_info,
		extra_curricular: state.extra_curricular_reducer.extra_curricular,
		experience: state.experience_reducer.experience,
		education: state.education_reducer.education,
		skills: state.skills_reducer.skills,
		languages: state.languages_reducer.languages,
	}
}

export default connect(mapStateToProps)(Html2Pdf);
