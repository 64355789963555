export const data_withvalidation = (data) => {

    let validated_data = { ...data }

    var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;




    //profile info email
    if (!re.test(validated_data.profile_info.email)) {
        validated_data = {
            ...validated_data,
            profile_info: {
                ...validated_data.profile_info,
                email: ''
            }
        }
    }

    var ra1 = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

    if (!ra1.test(validated_data.profile_info.linkedin_profile)) {
        validated_data = {
            ...validated_data,
            profile_info: {
                ...validated_data.profile_info,
                linkedin_profile: ''
            }
        }
    }


    //profile info contact number
    if (validated_data.profile_info.mobileno.length !== 10) {
        validated_data = {
            ...validated_data,
            profile_info: {
                ...validated_data.profile_info,
                mobileno: ''
            }
        }
    }


    return validated_data
}


