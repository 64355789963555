import React, { Component } from 'react';
import classes from './footer.module.css'
import { Link,NavLink } from 'react-router-dom';
import flipr_logo from './../../../img/group-2.png'
import facebook from './../../../img/fill-2.svg'
import twitter from './../../../img/twitter.svg'
import lnstgram from './../../../img/group-2.svg'
import linkedin from './../../../img/linkedin2.png'
//Route order is very important
class Footer extends Component {




    render() {
        return (
            <div >
                <div className={classes.footer_margin1}>
                    <div className={classes.footer}>
                        <div className={classes.link1}>
                            <Link className={classes.link_item} to="">ABOUT <span className={classes.item_space}>|</span></Link>
                            <Link className={classes.link_item} to="">BLOG <span className={classes.item_space}>|</span></Link>
                            <Link className={classes.link_item} to="">HELP & FAQ <span className={classes.item_space}>|</span></Link>
                            <Link className={classes.link_item} to="">TERMS & POLICIES</Link>
                        </div>
                        <div className={classes.link2}>
                            <a className={classes.link_item} to="" href="https://www.facebook.com/fliprindia/"><img src={facebook} /></a>
                            <a className={classes.link_item} to="" href="https://twitter.com/flipr_india"><img className={classes.icon_space1} src={twitter} /></a>
                            <a className={classes.link_item} to="" href="https://www.linkedin.com/company/fliprindia/"><img className={classes.icon_space2} src={linkedin} /></a>
                            <a style={{marginTop:'10px'}} className={classes.link_item} to="" href="https://www.instagram.com/fliprindia/"><img src={lnstgram} /></a>
                        </div>

                    </div>
                </div>
                <div className={classes.footer_margin2}>
                    <div className={classes.footer}>
                        <div className={classes.footer_copyright}>
                            &copy; 2020 | www.flipr.ai | All rights reserved.
                        </div>
                        <div className={classes.link2}>
                            <span className={classes.powered}>Powered by</span>
                            <img style={{ marginBottom: '13px' }} src={flipr_logo} />
                        </div>

                    </div>
                </div>
            </ div>
        );
    }

}




export default Footer;
