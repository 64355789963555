import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { firebase_database_base_url } from './env/firebase '
import Auth from './auth/auth';
import Student_form from './Student_form/student_form'
import Pdf_resume from './html2pdf/pdf_resume'
import Logout from './auth/logout/logout';
import { Route, Switch, Redirect } from 'react-router-dom';
import Navbar from './UI/navbar'


class Data_update extends Component {
    componentDidMount() {
        Axios.get(firebase_database_base_url + 'users.json?auth=' + localStorage.getItem('token') + '&orderBy="userId"&equalTo="' + localStorage.getItem('userId') + '"')
            .then(res => {
                const test = (Object.keys(res.data))
                if (localStorage.getItem('token') !== null) {
                    this.database_validaty(res.data[test])
                }
            })
            .catch(err => err)
    }

    database_validaty = (data) => {
        let updated_data = {
            profile_info: data.profile_info,
            extra_curricular: data.extra_curricular,
            experience: data.experience,
            education: data.education,
            skills: data.skills,
            languages: data.languages
        }
        if (updated_data.profile_info === undefined) {
            updated_data = {
                ...updated_data,
                profile_info: this.props.profile_info
            }
        }
        if (updated_data.extra_curricular === undefined) {
            updated_data = {
                ...updated_data,
                extra_curricular: []
            }
        }
        if (updated_data.experience === undefined) {
            updated_data = {
                ...updated_data,
                experience: []
            }
        }
        if (updated_data.education === undefined) {
            updated_data = {
                ...updated_data,
                education: this.props.education
            }
        }

        if (updated_data.education.certificate === undefined) {
            updated_data = {
                ...updated_data,
                education: {
                    ...updated_data.education,
                    certificate: this.props.education.certificate
                }
            }
        }
        if (updated_data.skills === undefined) {
            updated_data = {
                ...updated_data,
                skills: []
            }
        }
        if (updated_data.languages === undefined) {
            updated_data = {
                ...updated_data,
                languages: this.props.languages
            }
        }
        this.props.onuploaddata(updated_data)
    }



    render() {

        return (
            <div>
                <Navbar />
                <Switch>
                    <Route path="/student_form" component={Student_form} />
                    {(this.props.form_progress_counter > 95) ? <Route path="/pdf_resume" component={Pdf_resume} /> : <Redirect to="/student_form" />}
                    <Route path="/logout" component={Logout} />
                    <Route path="/" render={() => (<Redirect to="/student_form" />)} />
                    )} />
               </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId,
        user_databaseId: state.common_reducer.user_databaseId,
        profile_info: state.profile_info_reducers.profile_info,
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item, //for componenedidupdate
        experience: state.experience_reducer.experience,
        experience_item: state.experience_reducer.experience_item, //for componenedidupdate
        education: state.education_reducer.education,
        skills: state.skills_reducer.skills,
        languages: state.languages_reducer.languages,
        form_progress_counter: state.common_reducer.form_progress_counter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onuploaddata: ((upload_data) => dispatch({ type: 'upload_data', value: { upload_data: upload_data } })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Data_update);