import React, { Component } from 'react';
import './experience.css';
import { connect } from 'react-redux';
import ModernDatepicker from 'react-modern-datepicker';

class Experience extends Component {

    state = {
        reporting_managerError: false,
        contactError: false
    }

    componentDidMount() {
        if (this.props.experience.length === 0) {
            let experience = [...this.props.experience]

            experience.unshift({
                ...this.props.experience_item
            })
            this.props.onexperience(experience)
        }
    }

    alphwith_whitespace = (e, property) => {
        const re = /^[A-Za-z\s]+$/;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }

    onlynumber = (e, property) => {
        const re = /[0-9A-F:]+/g;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }




    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }




    addlistitem = () => {

        if (this.props.experience[0].role !== '' &&
            this.props.experience[0].company_name !== '' && this.props.experience[0].department !== '' &&
            this.props.experience[0].end !== '' && this.props.experience[0].start !== ''
        ) {
            let experience = [...this.props.experience]
            experience.unshift({
                ...this.props.experience_item
            })
            this.props.onexperience(experience)
        }


        // this.props.onexperience_latest(false)
    }


    listitemchange = (itemkey, itemvalue, experience) => {
        let listitem = [...this.props.experience]
        listitem.map(listitem => {
            if (listitem === experience) {
                listitem[itemkey] = itemvalue
            }
        })
        this.props.onexperience(listitem)
    }

    removelistitem = (experience) => {
        let temp = this.props.experience.filter((item) => item !== experience);
        this.props.onexperience(temp)
    }

    render() {
        // const total_array_elememt = this.props.experience.length;
        let list = [];
        list = this.props.experience

        // if (this.props.experience_latest && total_array_elememt > 0) {
        //     list = list.slice(0, total_array_elememt - 1)
        // }

        let show = list.map((experience, index) => (
            <div key={index}>
                <div className="removebtn">
                    {(this.props.experience.length > 1) ? < button type="button" className="btn btn-primary btn-trans" onClick={() => this.removelistitem(experience)} > Remove</button> : null}
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Experience Type*</label>
                        <select className="form-control" id="experience_type"
                            value={experience.activity_type}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}>
                            <option value="Internsihp">Internsihp</option>
                            <option value="Job">Job</option>
                            <option value="Project">Project</option>
                            <option value="Freelance">Freelance</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Role*</label>
                        <input type="text" className="form-control" id="role"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.role} placeholder="Role" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Company*</label>
                        <input type="text" className="form-control" id="company_name"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.company_name} placeholder="Company" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Department*</label>
                        <input type="text" className="form-control" id="department"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.department} placeholder="Department" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">Start Date*</label>
                        <ModernDatepicker className="date-picker"
                            className="form-control"
                            date={experience.start}
                            format={'DD-MM-YYYY'}
                            showBorder
                            placeholder="Select Date"
                            id="start"
                            onChange={(e) => this.listitemchange('start', e, experience)}
                            placeholder={'Select Date'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">End Date*</label>
                        <ModernDatepicker className="date-picker"
                            className="form-control"
                            date={experience.end}
                            format={'DD-MM-YYYY'}
                            showBorder
                            placeholder="End Date"
                            id="end"
                            onChange={(e) => this.listitemchange('end', e, experience)}
                            minDate={ experience.start}
                            placeholder={'Select Date'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="percentage">Reporting Manager</label>
                        <input type="text" className="form-control" id="reporting_manager"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.reporting_manager} placeholder="Reporting Manager"
                            onKeyPress={(e) => this.alphwith_whitespace(e, 'reporting_managerError')} placeholder="Reporting Manager" />
                         <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Contact</label>
                        <input type="text" className="form-control" id="contact_no" maxLength="10"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.contact_no} placeholder="Contact"
                            onKeyPress={(e) => this.onlynumber(e, 'contactError')} placeholder="Contact" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="inputPassword4">Responsibilities</label>
                        <textarea className="form-control customtextarea" id="responsibilities" maxLength="250"
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, experience)}
                            value={experience.responsibilities} rows="5"></textarea>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="topborder"></div>
            </div >
        ))


        return (
            <div>
                <p className="profile-info-header">Experience / Internships
                <button style={{ marginLeft: 'auto' }} type="button" onClick={this.addlistitem} className="btn btn-primary btn-add"> + Add</button></p>
                {show}
                <div className="custom_btn_div">
                    <button type="button" onClick={() => this.curentstateHandler('educ')} className="btn btn-primary btn-trans fixed_btn_back">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-arrow-back">
                                        &larr;
                                    </td>
                                    <td >
                                        Education
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button type="button" onClick={() => this.curentstateHandler('skills')} className="btn btn-primary btn-trans fixed_btn_for">
                        <table>
                            <tbody>
                                <tr>
                                    <td >
                                        Skills
                                    </td>
                                    <td className="btn-arrow-for">
                                        &rarr;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        experience: state.experience_reducer.experience,
        experience_latest: state.experience_reducer.experience_latest,
        experience_item: state.experience_reducer.experience_item
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        onexperience: ((experience) => dispatch({
            type: 'experience',
            value: { experience: experience }
        })),
        onexperience_latest: ((experience_latest) => dispatch({
            type: 'experience_latest',
            value: { experience_latest: experience_latest }
        })),
        onexperience_item: ((experience_item) => dispatch({
            type: 'experience_item',
            value: { experience_item: experience_item }
        }))
    };
};






export default connect(mapStateToProps, mapDispatchToProps)(Experience);