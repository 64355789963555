import React, { Component } from 'react';
import classes from './skills.css';
import { connect } from 'react-redux';
import Axios from 'axios';
import { firebase_database_base_url } from './../../../env/firebase '
import Loader_icon from './../../../UI/loader/loader_icon/loader_icon'

class Skills extends Component {


    state = {
        show: false,
        skills_list: [],
        total_data: []
    }

    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        Axios.get(firebase_database_base_url + 'skills.json')
            .then(res => {
                let fetchedorders = []

                for (let key in res.data) {
                    //for key value if key variable
                    fetchedorders.push({
                        skills: res.data[key],
                        id: Math.random()
                    })
                }
                this.setState({ skills_list: fetchedorders, total_data: fetchedorders })
            })
            .catch(err => err)
    }




    hideskills = () => {
        setTimeout(() => {
            this.setState({ show: false })
        }, 500);

    }

    showskills = () => {
        this.setState({ show: true })
    }
    addskills = (skill_show) => {
        let skills_addition = [...this.props.skills]
        skills_addition.push({
            skills: skill_show.skills,
            value: '5'
        })
        this.props.onaddskills(skills_addition)
    }


    listitemchange = (itemkey, itemvalue, show_skills_range) => {
        let listitem = [...this.props.skills]
        listitem.map(listitem => {
            if (listitem === show_skills_range) {
                listitem[itemkey] = itemvalue
            }
            return 0
        })
        this.props.onaddskills(listitem)
    }

    removeskills = (remove_skill_range) => {
        let listitem = [...this.props.skills]
        let temp = listitem.filter((item) => item.skills !== remove_skill_range.skills)
        this.props.onaddskills(temp)
    }

    button_show = (skill_show1) => {
        let test = [...this.props.skills]
        let temp = test.filter((skill_show) => skill_show.skills === skill_show1.skills)
        return temp.length
    }

    searchbar = (e) => {

        let searched_data = []
        if (e.target.value !== '') {
            this.state.total_data.map(skill_list => {
                if ((skill_list.skills.toLowerCase()).startsWith(e.target.value.toLowerCase())) {
                    searched_data.push(skill_list)
                }
                return 0
            })
            this.setState({ skills_list: searched_data })
        }
        else {
            this.setState({ skills_list: this.state.total_data })
        }
    }

    render() {
        let show_maping = this.state.skills_list.map(skill_show => (
            <div key={skill_show.id}>
                <li className="suggestion_item">{skill_show.skills}
                    <div>
                        {this.button_show(skill_show) > 0 ?
                            <button onClick={() => this.removeskills(skill_show)} className="btn btn-primary btn-submit btn-fixed-w"> Remove</button> :
                            <button onClick={() => this.addskills(skill_show)} className="btn btn-primary btn-submit btn-fixed-w"> Add</button>}
                    </div>
                </li>
            </div>
        ))

        let show = <div>
            {this.state.show ? <div className="suggestion_container text-center">
                {(this.state.skills_list.length === 0) ? <div style={{ margin: '100px' }}><Loader_icon /></div> :
                    <ul className="suggestion_list">
                        {show_maping}
                    </ul>}
                {/* <div className="add_custom_skills">
                    <button onClick={() => this.props.onPop_up_custom_lang_skill('Skill')}
                        className="add_custom_skills_button"><img src="./img/add.png" alt="" />Add Custom Skill</button>
                </div> */}
            </div> : null
            }
        </div>


        let show_skills_range = this.props.skills.map((show_skills_range, index) => (
            <div key={index} className="progressBar_Allskills">
                <div className="progressBar_Allskills-description">{show_skills_range.skills}</div>
                <div className="progressBar_Allskills-bar">
                    <input type="range" step="1" min="0" max="10" id="value" value={show_skills_range.value}
                        onChange={(e) => this.listitemchange(e.target.id, e.target.value, show_skills_range)} />
                    <div className="progressBar_scale">
                        <span>0</span>
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                        <span>5</span>
                        <span>6</span>
                        <span>7</span>
                        <span>8</span>
                        <span>9</span>
                        <span>10</span>
                    </div>
                </div>
                <div className="progressBar_Allskills-close">
                    <button onClick={() => this.removeskills(show_skills_range)}>X</button>
                </div>
            </div>
        ))



        return (
            <div >
                <p className="profile-info-header">My Skills</p>
                <div className="searchBar-wrapper">
                    <h3 className="searchBar-heading">Add Skills</h3>
                    <div className={classes.searchBar_input_container}>
                        <input onFocus={this.showskills} onBlur={this.hideskills} type="text" placeholder="Search Here..."
                            onChange={(e) => this.searchbar(e)} className="form-control search_bar_w" />
                        <button className="searchBar-button"><img src="./img/search.png" alt="" /></button>
                    </div>
                    {show}
                </div>
                <div className="progressBar-container">
                    <div className="progressBar-heading">
                        <div className="progressBar-skill">Skill</div>
                        <div className="progressBar-strength">Skill Strength</div>
                    </div>
                    {show_skills_range}
                </div>
                <div className="custom_btn_div">
                    <button type="button" onClick={() => this.curentstateHandler('expe')} className="btn btn-primary btn-trans fixed_btn_back">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-arrow-back">
                                        &larr;
                                </td>
                                    <td >
                                        Experience
                               </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button type="button" onClick={() => this.curentstateHandler('ex_curr')} className="btn btn-primary btn-trans fixed_btn_for">
                        <table>
                            <tbody>
                                <tr>
                                    <td >
                                        Ex-curricular
                                    </td>
                                    <td className="btn-arrow-for">
                                        &rarr;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
            </div>
        )

    }
}




const mapStateToProps = state => {

    return {
        skills: state.skills_reducer.skills
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        onaddskills: ((skills) => dispatch({
            type: 'add_skill',
            value: { skills: skills }
        })),
        onPop_up_custom_lang_skill: ((pop_up_custom_lang_skill, type) => dispatch({
            type: 'pop_up_custom_lang_skill', value: {
                pop_up_custom_lang_skill: pop_up_custom_lang_skill
            }
        })),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Skills);







