import React, { Component } from 'react';
import { connect } from 'react-redux';
import { data_withvalidation } from './../forms/form_validation';



class Progress_bar extends Component {

    componentDidMount() {
        this.Progress_bar_update()
    }

    componentDidUpdate() {
        this.Progress_bar_update()
    }

    Progress_bar_update = () => {
        let data = {
            profile_info: this.props.profile_info,
            extra_curricular: [...this.props.extra_curricular],
            experience: [...this.props.experience],
            education: this.props.education,
            skills: [...this.props.skills],
            languages: [...this.props.languages]
        }
        let count = 0
        for (let key in data_withvalidation(data).profile_info) {
            if (data_withvalidation(data).profile_info[key] !== '') {

                if (data_withvalidation(data).profile_info[key].startsWith('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO')) {
                    count = count - (100 / 36)
                }
                count = count + (100 / 36)
            }
        }

        const education_key = ["graduation", "tenth", "twelfth"]
        education_key.map(education_key => {
            for (let key in data.education[education_key]) {
                if (data.education[education_key][key] !== '') {
                    count = count + (100 / 36)
                }
            }
            return 0
        })

        const expe_length = data.experience.length - 1
        data.experience.map((experience, index) => {
            if (index === expe_length) {
                if (experience.role !== "" && experience.company_name !== "" &&
                    experience.department !== "" && experience.start !== "" &&
                    experience.end !== "") {
                    count = count + (100 / 36)
                }
            }
            return 0
        })

        const ex_curr_length = data.extra_curricular.length - 1
        data.extra_curricular.map((extra_curricular, index) => {
            if (index === ex_curr_length) {
                if (extra_curricular.organization !== "" && extra_curricular.cause !== "") {
                    count = count + (100 / 36)
                }
            }
            return 0
        })


        const otherkey = ["skills"]

        otherkey.map(otherkey => {
            if (data[otherkey].length > 0) {
                count = count + (100 / 36)
            }
            return 0
        })


        let languages = false
        for (let key in data.languages) {
            if (data.languages[key].read) {
                languages = true
                break;
            }
            else if (data.languages[key].write) {
                languages = true
                break;
            }
            else if (data.languages[key].speak) {
                languages = true
                break;
            }
        }

        if (languages) {
            count = count + (100 / 36)
        }

        this.props.onform_progress_counter(count)
    }





    render() {
        return (
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: this.props.form_progress_counter + '%' }} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100">
                    <span style={{ color: 'black' }}></span></div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        form_progress_counter: state.common_reducer.form_progress_counter,
        profile_info: state.profile_info_reducers.profile_info,
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item, //for componenedidupdate
        experience: state.experience_reducer.experience,
        experience_item: state.experience_reducer.experience_item, //for componenedidupdate
        education: state.education_reducer.education,
        skills: state.skills_reducer.skills,
        languages: state.languages_reducer.languages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onform_progress_counter: ((form_progress_counter) =>
            dispatch({ type: 'form_progress_counter', value: { form_progress_counter: form_progress_counter } })),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress_bar);