import React, { Component } from 'react';
import './auth.css';
import flipr_logo from './../../img/group-2.png'
import { Route, Switch, Link } from 'react-router-dom';
import Password from './password_set/password_set'
import Login from './Login/Login';
import Signup from './signup/signup'

class Auth extends Component {

    render() {
        return (
            <div className="main-div">
                <div className="auth_bg">
                    <div className="card-center main_div_card">
                        <Switch>
                            <Route path="/password_set" component={() => (
                                <Password refresh_token={this.props.refresh_token} />
                            )} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/" component={() => (
                                <Login refresh_token={this.props.refresh_token} />
                            )} />
                        </Switch>
                    </div>
                </div>

                <footer>
                    <div className="footer_main">
                        <div className="link1">
                            <Link className="link-item" to="">ABOUT <span className="item-space">|</span></Link>
                            <Link className="link-item" to="">BLOG <span className="item-space">|</span></Link>
                            <Link className="link-item" to="">HELP & FAQ <span className="item-space">|</span></Link>
                            <Link className="link-item" to="">TERMS & POLICIES</Link>
                        </div>
                        <div className="link2">
                            <span className="powered">Powered by</span>
                            <img style={{ marginBottom: '13px' }} src={flipr_logo} alt="image" />
                        </div>

                    </div>
                </footer>
            </div>
        );
    }
}



export default Auth;