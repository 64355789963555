import React, { Component } from 'react';

//bootstrap npm package start
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
//bootstrap npm package start
import { Route, Switch } from 'react-router-dom';
// import {browserHistory } from 'react-router'
import './App.css';
import Auth from './component/auth/auth';
import { connect } from 'react-redux';
import Axios from 'axios'
import { firebase_api_key } from '../src/component/env/firebase ';
import Data_update from './component/data_update'
import Popup_custom_lang_skill from './component/UI/pop-up/pop-up_custom_lang_skill';
import Pop_up_message from './component/UI/pop-up/pop-up_message';
import Loader from './component/UI/loader/loader_bg/loader'


//Route order is very important
class App extends Component {

  constructor(props) {
    super(props)

    this.getParameterByName = (name) => {
      name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if (results == null)
        return "";
      else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    var mode = this.getParameterByName('mode');;
    if (mode === 'resetPassword') {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('userId')
      localStorage.removeItem('user_databaseId')
      localStorage.removeItem('refreshToken')
      this.props.onAuthState(null, null, null, null)
    }
  }

  componentDidMount() {

    //start auto login

    this.props.onAuthState(localStorage.getItem('token'),
      localStorage.getItem('userId'),
      localStorage.getItem('user_databaseId'),
      localStorage.getItem('expirationDate'),
      localStorage.getItem('refreshToken'));
    if (localStorage.getItem('token') !== null) {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate < new Date()) {
        this.call_new_id_token();
        this.refresh_token();
      }
      else {
        this.onrefresh_auth_call((expirationDate.getTime()) - (new Date().getTime()) - (60 * 1000))
      }
    }
  }

  onrefresh_auth_call = (timedelay) => {
    setTimeout(() => {
      this.call_new_id_token();
      this.refresh_token();
    }, timedelay)
  }

  call_new_id_token = () => {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refreshToken')
    }
    Axios.post('https://securetoken.googleapis.com/v1/token?key=' + firebase_api_key, data)
      .then(res => {
        const expirationDate = new Date(new Date().getTime() + res.data.expires_in * 1000);
        localStorage.setItem('token', res.data.id_token);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('userId', res.data.user_id)
        localStorage.setItem('refreshToken', res.data.refresh_token);
        this.props.onAuthState(res.data.id_token, res.data.user_id,
          localStorage.getItem('user_databaseId'), expirationDate, res.data.refresh_token);

      })
      .catch(err => err)
  }

  refresh_token = () => {
    setInterval(() => {
      this.call_new_id_token();
    }, 60 * 59 * 1000);
  }



  render() {

    let route = (
      <Switch>
        <Route path="/" component={() => (<Auth refresh_token={this.refresh_token} />)} />
      </Switch>
    )

    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    if ((expirationDate >= new Date())) {
      route = (
        <Switch>
          <Route path="/" component={Data_update} />
          )} />
        </Switch>
      )
    }


    return (
      <div style={{ width: '100%' }}>
        <Popup_custom_lang_skill />
        <Pop_up_message />
        <Loader />
        <Switch>
          {route}
        </Switch>
        {/* {((this.props.token !== null) && (expirationDate >= new Date())) ? <Redirect to="/student_form" /> : null} */}
      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    token: state.common_reducer.token,
    userid: state.common_reducer.userId,
    expirationDate: state.common_reducer.expirationDate,
    user_databaseId: state.common_reducer.user_databaseId,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onAuthState: ((token, userId, user_databaseId, expirationDate, refreshToken) => dispatch({
      type: 'authcheck', value: {
        token: token, userId: userId,
        user_databaseId: user_databaseId,
        expirationDate: expirationDate,
        refreshToken: refreshToken
      }
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
