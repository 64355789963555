import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as emailjs from 'emailjs-com'
import './pop-up_custom_lang_skill.css';

class Pop_up_custom_lang_skill extends Component {

    state = {
        value: ''
    }

    add_value = (e) => {
        this.setState({ value: e.target.value })
    }

    send_email = () => {
        const templateParam = {
            to_name: 'admin',
            from_name: 'Flipr',
            message_html: this.state.value
        }
        emailjs.send('gmail', 'template_1', templateParam, 'user_QbaUn5hY18exMZMPr1gOy')
            .then(res => {
                this.props.onPop_up_custom_lang_skill('')
                this.props.onClosePop_up_message('Email has been sent')
            })
            .catch(err => {
                this.props.onClosePop_up_message('Email not send')
            })
    }

    render() {
        let pop_up

        pop_up = <div>
            {(this.props.pop_up_custom_lang_skill !== '') ?
                <div className="pop-up_add">
                    <div className="add-div">
                        <h1 class="add_skill">Add {this.props.pop_up_custom_lang_skill}</h1>
                        <input class="form-control custom_add_skill" onChange={(e) => this.add_value(e)}
                            type="text" value={this.state.value} />
                        <div className="text-right">
                            <button style={{ height: '4.7rem', marginRight: '10px' }} onClick={() => this.props.onPop_up_custom_lang_skill('')}
                                className="btn btn-primary btn-trans">Cancel</button>
                            <button disabled={this.state.value === ""} onClick={this.send_email} className="btn btn-primary btn-submit">Submit</button>
                        </div>
                    </div>
                </div> : null}
        </div>


        return (
            <div>
                {pop_up}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pop_up_custom_lang_skill: state.common_reducer.pop_up_custom_lang_skill
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onClosePop_up_message: ((pop_up_message) => dispatch({
            type: 'pop_up_message', value: {
                pop_up_message: pop_up_message
            }
        })),
        onPop_up_custom_lang_skill: ((pop_up_custom_lang_skill, type) => dispatch({
            type: 'pop_up_custom_lang_skill', value: {
                pop_up_custom_lang_skill: pop_up_custom_lang_skill
            }
        })),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Pop_up_custom_lang_skill);