import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import './navbar.css';
import navlogo from './../../img/college_logo.jpg'
import Logout_icon from './../../img/exit.svg'
import flipr_logo from './../../img/group-2.png'
import profile_image from './../../img/user-profile.png'

class Navbar extends Component {


    render() {
        let image
        if (this.props.user_image === "") {
            image = profile_image
        }
        else {
            image = this.props.user_image
        }
        return (
            <header>
                <nav className="navbar navbar-expand-lg bg-light fixed-top">
                    <div className="container-fluid narrow">
                        <div className="logo-vert">
                            <Link className="navbar-brand" aria-label="logo" to="">
                                <img className="nav-logo" src={flipr_logo} alt="" />
                            </Link>
                            <span className="navbar-brand-text" href="index.html">
                                {/* FliprConnect */}
                            </span>
                        </div>
                        <div className="" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item user-image">
                                    <img className="user-img-tag" align="middle" src={image} /><span>dfssdcs</span>
                                </li>
                                <li className="nav-item">
                                    <div className="vertical-line"></div>
                                </li>
                                <li className="nav-item user-imag" style={{ paddingTop: '6px' }}>
                                    <Link title="Signout" to="/logout"><i><img id="icon-bg1" src={Logout_icon} alt="gmail" /></i></Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        );
    }
}


const mapStateToProps = state => {
    return {
        user_image: state.profile_info_reducers.profile_info.image
    }
}


export default connect(mapStateToProps)(Navbar);