import React, { Component } from 'react';
import './language.css';
import { connect } from 'react-redux';
import Axios from 'axios';
import { firebase_database_base_url } from './../../../env/firebase '
import Loader_icon from './../../../UI/loader/loader_icon/loader_icon'

class Language extends Component {

    state = {
        show: false,
        languages_list: [],
        total_data: []
    }
    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }

    showskills = () => {
        this.setState({ show: true })
    }

    hideskills = () => {
        setTimeout(() => {
            this.setState({ show: false })
        }, 500);
    }


    componentDidMount() {
        Axios.get(firebase_database_base_url + 'languages.json')
            .then(res => {
                let languages = []
                for (let key in res.data) {
                    //for key value if key variable
                    languages.push({
                        languages: res.data[key],
                        id: Math.random()
                    })
                }
                this.setState({ languages_list: languages, total_data: languages })
            })
            .catch(err => err)
    }

    button_show = (language_show1) => {
        let test = [...this.props.languages]
        let temp = test.filter((language_show) => language_show.languages === language_show1.languages)
        return temp.length
    }

    addlanguages = (language_show) => {
        let languages_addition = [...this.props.languages]
        languages_addition.push({
            languages: language_show.languages,
            read: false,
            write: false,
            speak: false
        })
        this.props.onaddlanguages(languages_addition)
    }

    removelanguages = (remove_language_checkbox) => {

        let listitem = [...this.props.languages]
        let temp = listitem.filter((item) => item.languages !== remove_language_checkbox.languages)
        this.props.onaddlanguages(temp)

    }

    listitemchange = (itemkey, itemvalue, show_language_checkbox) => {

        let listitem = [...this.props.languages]
        listitem.map(listitem => {
            if (listitem.languages === show_language_checkbox.languages) {

                if (!listitem[itemkey]) {
                    listitem[itemkey] = true
                }
                else {
                    listitem[itemkey] = false
                }

            }
        })
        this.props.onaddlanguages(listitem)
    }

    searchbar = (e) => {
        let searched_data = []
        if (e.target.value !== '') {
            this.state.total_data.map(language_list => {
                if ((language_list.languages.toLowerCase()).startsWith(e.target.value.toLowerCase())) {
                    searched_data.push(language_list)
                }
            })
            this.setState({ languages_list: searched_data })
        }
        else {
            this.setState({ languages_list: this.state.total_data })
        }
    }



    render() {
        let show_maping = this.state.languages_list.map((language_show, index) => (
            <div key={index}>
                <li className="suggestion_item">{language_show.languages}
                    <div>
                        {this.button_show(language_show) > 0 ?
                            <button tupe="button" onClick={() => this.removelanguages(language_show)} className="btn btn-primary btn-submit btn-fixed-w"> Remove</button> :
                            <button tupe="button" onClick={() => this.addlanguages(language_show)} className="btn btn-primary btn-submit btn-fixed-w"> Add</button>}
                    </div>
                </li>
            </div>
        ))

        let show = <div>
            {this.state.show ? <div className="suggestion_container text-center">
                {(this.state.languages_list.length === 0) ?  <div style={{ margin: '100px' }}><Loader_icon /></div> :
                    <ul className="suggestion_list">
                        {show_maping}
                    </ul>}
                {/* <div className="add_custom_skills">
                    <button onClick={() => this.props.onPop_up_custom_lang_skill('Language')}
                        className="add_custom_skills_button" ><img src="./img/add.png" alt="" />Add Custom Language</button>
                </div> */}
            </div> : null
            }
        </div>

        let show_language_checkbox = this.props.languages.map((show_language_checkbox, index) => (
            <div key={index} className="progressBar_Allskills checkBox">
                <div className="progressBar_Allskills-description">{show_language_checkbox.languages}</div>
                <div className="progressBar_Allskills-bar" >
                    <div className="checkBox-container">
                        <div>
                            <input className="custom-checkbox" name="read" id={"read_" + index} type="checkbox" checked={show_language_checkbox.read}
                                onChange={(e) => this.listitemchange(e.target.name, e.target.value, show_language_checkbox)} />
                            <label className="custom-checkbox-label" htmlFor={"read_" + index}>Read</label>
                        </div>
                        <div>
                            <input className="custom-checkbox" name="write" id={"write_" + index} type="checkbox" checked={show_language_checkbox.write}
                                onChange={(e) => this.listitemchange(e.target.name, e.target.value, show_language_checkbox)} />
                            <label className="custom-checkbox-label" htmlFor={"write_" + index}>Write</label>
                        </div>
                        <div>
                            <input className="custom-checkbox" name="speak" id={"speak_" + index} type="checkbox" checked={show_language_checkbox.speak}
                                onChange={(e) => this.listitemchange(e.target.name, e.target.value, show_language_checkbox)} />
                            <label className="custom-checkbox-label" htmlFor={"speak_" + index}>Speak</label>
                        </div>
                    </div>
                </div>
                <div className="progressBar_Allskills-close">
                    <button onClick={() => this.removelanguages(show_language_checkbox)}>X</button>
                </div>
            </div>
        ))



        return (
            <div>
                <p className="profile-info-header">Language</p>
                <div className="searchBar-wrapper">
                    <h3 className="searchBar-heading">Add Languages</h3>
                    <div className="searchBar_input-container">
                        <input onFocus={this.showskills} onBlur={this.hideskills} type="text" className="form-control search_bar_w"
                            placeholder="Search Here ..." onChange={(e) => this.searchbar(e)} />
                        <button className="searchBar-button"><img src="./img/search.png" alt="" /></button>
                    </div>
                    {show}
                </div>
                <div className="progressBar-container">
                    <div className="progressBar-heading">
                        <div className="progressBar-skill">Language</div>
                        <div className="progressBar-strength">Proficiency</div>
                    </div>
                    {show_language_checkbox}
                </div>
                <div className="custom_btn_div">
                    <button type="button" onClick={() => this.curentstateHandler('ex_curr')} className="btn btn-primary btn-trans fixed_btn_back">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-arrow-back">
                                        &larr;
                                </td>
                                    <td >
                                        Extra-Curricular
                               </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        languages: state.languages_reducer.languages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        onaddlanguages: ((languages) => dispatch({
            type: 'add_language',
            value: { languages: languages }
        })),
        onPop_up_custom_lang_skill: ((pop_up_custom_lang_skill, type) => dispatch({
            type: 'pop_up_custom_lang_skill', value: {
                pop_up_custom_lang_skill: pop_up_custom_lang_skill
            }
        })),
    };
};




export default connect(mapStateToProps, mapDispatchToProps)(Language);