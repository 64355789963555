
const initialState = {
    profile_info: {
        image: '',
        student_id: '',
        firstname: '',
        lastname: '',
        mobileno: '',
        dob: '',
        email: '',
        gender: '',
        city: '',
        linkedin_profile: '',
        collegename: '',
        shortbio: ''
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //profile information start
        case 'upload_data':
            let data = { ...action.value.upload_data.profile_info }
            // if (action.value.upload_data.profile_info.image === '') {
            //     data = {
            //         ...data,
            //         image: profile_image
            //     }
            // }
            return {
                ...state,
                profile_info: data
            }

        case 'profil_info_change':
            const itemkey = action.value.itemkey
            return {
                ...state,
                profile_info: {
                    ...state.profile_info,
                    [itemkey]: action.value.itemvalue
                }
            }

        //profile information end
        default:
            return state;
    }
};

export default reducer;