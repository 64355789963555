import React, { Component } from 'react';
import { connect } from 'react-redux';

class Pop_up extends Component {



    render() {


        return (
            <div>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loader: state.common_reducer.loader
    }
}





export default connect(mapStateToProps)(Pop_up);