import React, { Component } from 'react';
import { connect } from 'react-redux';

import './pop-up_message.css';

class Pop_up extends Component {



    render() {
        let pop_up

        pop_up = <div>
            {(this.props.pop_up_message !== '') ? <div className="pop-up">
                <div className="message-div">
                    <div className="text-center">
                        {this.props.pop_up_message}
                    </div>
                    <div className="text-center">
                        <button onClick={() => this.props.onClosePop_up_message('')} style={{ width: '100px', fontSize: '1.6rem' }}
                            className="btn btn-primary btn-submit">Ok</button>
                    </div>
                </div>
            </div> : null}
        </div>
        return (
            <div>
                {pop_up}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pop_up_message: state.common_reducer.pop_up_message
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onClosePop_up_message: ((pop_up_message) => dispatch({
            type: 'pop_up_message', value: {
                pop_up_message: pop_up_message
            }
        })),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Pop_up);