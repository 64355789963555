import React from 'react';
import flipr_logo from './fliprlogo.js';
import phone from './phone.png';
import email from './email.png';
import linkdn from './linkdn.png';

let Html2Pdf= (props) => {

    let data= props.data;
    let cv_image_src= data.profile_info.image ? data.profile_info.image : "";
    let cv_basicDetails_name= data.profile_info.firstname + " " + data.profile_info.lastname;
    let cv_basicDetails_qualification= data.education.graduation ? (data.education.graduation.gr_degree_program + ', ' + data.education.graduation.gr_specialization) : ' ';
    let cv_basicDetails_phone= data.profile_info.mobileno;
    let cv_basicDetails_email= data.profile_info.email;
    let cv_basicDetails_linkdin= data.profile_info.linkedin_profile;

    let education_template= [], 
    employment_template= [], 
    extra_curricular_template= [], 
    skills_list_template= [], 
    Language_template= [], 
    personal_template= [];        

    Object.keys(data.education).forEach((itm, indx) => {
        switch(itm)
        {   
            case "graduation" :
            {   
                if(Object.values(data.education.graduation).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                    education_template.push(
                    <ul className= "CD_block education_sub_block" key= {indx}>
                        <li className= "education_item" key= "1">{data.education.graduation.gr_degree_program}  |  {data.education.graduation.gr_year_graduation}</li>
                        <li className= "education_item" key= "2">{data.education.graduation.gr_specialization}</li>
                        <li className= "education_item" key= "3">{data.profile_info.collegename}</li>
                        <li className= "education_item" key= "4">Grade: {data.education.graduation.gr_overall_percentage}</li>
                    </ul>)   
                }
                break;
            }
            case "twelfth" :
            {
                if(Object.values(data.education.twelfth).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                    education_template.push(
                    <ul className= "CD_block education_sub_block" key= "2">
                        <p className= "education_item">{data.education.twelfth.tw_education_type} &nbsp; | &nbsp; {data.education.twelfth.tw_board} &nbsp; | &nbsp; {data.education.twelfth.tw_year_completion}</p>
                        <p className= "education_item">Overall Grade: {data.education.twelfth.tw_overall_percentage}</p>
                        <p className="education_item">Math score: {data.education.twelfth.tw_math_score}</p>
                        <p className="education_item">Physics score: {data.education.twelfth.tw_physics_score}</p>
                    </ul>)
                }
                break;
            }
            case "tenth" :
            {
                if(Object.values(data.education.tenth).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                     education_template.push(
                    <ul className= "CD_block education_sub_block" key= "3">
                        <p className="education_item">{data.education.tenth.te_education_type} &nbsp; | &nbsp; {data.education.tenth.te_board} &nbsp; | &nbsp; {data.education.tenth.te_year_completion}</p>
                        <p className="education_item">Overall Grade: {data.education.tenth.te_overall_percentage}</p>
                        <p className="education_item">Math score: {data.education.tenth.te_math_score}</p>
                        <p className="education_item">Physics score: {data.education.tenth.te_physics_score}</p>
                    </ul>)
                }
                break;
            }
            default: break;
        }
    });

    if(data.experience)
    {
        employment_template= Object.values(data.experience).map((itm, indx) => {
            return (<ul className= "CD_block work_sub_block" key= {indx}>
                <li className= "work_experience_item" key= "1">{itm.experience_type} | {itm.start} to {itm.end}</li>
                <li className= "work_experience_item" key= "2">{itm.company_name}</li>
                <li className= "work_experience_item" key= "3"><p className= "work_details">{itm.responsibilities}</p></li>
            </ul>)
        });
    }

    if(data.extra_curricular)
    {
        Object.values(data.extra_curricular).map((itm, index) => {
            extra_curricular_template.push(
            <div className= "CD_block extra_curricular_sub_block" key= {index}>
                <p className="extra_curricular_item">{itm.cause} &nbsp; |  {itm.activity_type}&nbsp; </p>
                <p className="extra_curricular_item">{itm.organization}</p>
            </div>)
        });
    }

     if(data.skills)
    {
        skills_list_template= Object.values(data.skills).map((itm, index) => <li className= "skills_item" key= {index}>{itm.skills}</li>)     
        skills_list_template= <ul className= "CD_block skills_list">{skills_list_template}</ul>;
    }

     if(data.languages)
    {
        Object.values(data.languages).forEach( (itm, index) => {
            let read= <span key= "1">Read &nbsp;</span>, write= <span key= "2">Read &nbsp;</span>, speak= <span key= "3">Speak &nbsp;</span>
            let temp= [itm.read ? read : null, itm.write ? write: null, itm.speak ? speak : null]
            Language_template.push( 
            <li className= "language_item" key= {index}>
                <span className= "language_name">{itm.languages}</span>
                <span className= "labguage_prof">{temp}</span>
            </li>)
        });
    }

    personal_template= ( 
     <ul className= "CD_block">
        <li className= "personal_item"><span className= "personal_type">DOB: </span><span className= "personal_val">{data.profile_info.dob}</span></li>
        <li className= "personal_item"><span className= "personal_type">Location</span><span className= "personal_val">{data.profile_info.city}</span></li>
        <li className= "personal_item"><span className= "personal_type">Gender</span><span className= "personal_val">{data.profile_info.gender}</span></li>
    </ul>)

    return(
        <div className="main_div" id= "container">

            <header className= "CD_block">
                <div id= "head_shot_holder"><img id= "head_shot" src= {cv_image_src} alt= "image"/></div>
                <div id= "basic_info">
                    <h1 id= "name">{cv_basicDetails_name}</h1>
                    <h2 id= "degree"> {cv_basicDetails_qualification}</h2>
                    <ul id= "contact">
                        <li><img src= {phone} className= "list_image"/><span>{cv_basicDetails_phone}</span></li>
                        <li><img src= {email} className= "list_image"/><span>{cv_basicDetails_email}</span></li>
                        <li><img src= {linkdn} className= "list_image"/><span>{cv_basicDetails_linkdin}</span></li>
                    </ul>
                </div>
            </header>
                <h2 className= "CD_sub_header" id= "skills_header">Skill</h2>
                {skills_list_template}
                <h2 className= "CD_sub_header" id= "language_header">Language Proficiency</h2>
                <ul className= "CD_block">{Language_template}</ul>
                <h2 className= "CD_sub_header" id= "work_experience_header">Work Experience</h2>
                {employment_template}
                <h2 className= "CD_sub_header" id= "personal_header">Personal</h2>
                {personal_template}
                <h2 className= "CD_sub_header" id= "education_header">Education</h2>
                {education_template}
                <h2 className= "CD_sub_header" id= "extra_curricular_header">Extra Curricular Activities</h2>
                {extra_curricular_template}
        </div>)
}

export default Html2Pdf;

