import React, { Component } from 'react';
import { connect } from 'react-redux';

import './loader.css';

class Pop_up extends Component {



    render() {
        let pop_up

        pop_up = <div>
            {(this.props.loader) ? <div className="pop-up">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div> : null}
        </div>
        return (
            <div>
                {pop_up}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loader: state.common_reducer.loader
    }
}





export default connect(mapStateToProps)(Pop_up);