import React, { Component } from 'react';
import classes from './profile_information.module.css';
import { connect } from 'react-redux';
import Axios from 'axios';
import camera from './../../../../img/camera.png'
import ModernDatepicker from 'react-modern-datepicker';


class Profile_Information extends Component {

    state = {
        mobilenoError: false,
        emailError: false,
        firstnameError: false,
        lastnameError: false,
        loader: false,
        student_idError: false,
        linkedinError: false
    }


    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }

    onlyalph = (e, property) => {
        const re = /^[a-zA-Z]*$/;
        if (!re.test(e.key)) {
            e.preventDefault();
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }

    }

    onlynumber = (e, property) => {
        const re = /[0-9A-F:]+/g;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            if (this.props.profile_info.mobileno.length >= 10) {
                this.setState({ [property]: false })
            }
        }
    }

    onlyalphnumber = (e) => {
        const re = /[^a-zA-Z0-9]/g   //allow only special character

        if (re.test(e.key)) {
            e.preventDefault()
            this.setState({ student_idError: true })
        }
        else {
            this.setState({ student_idError: false })
        }
    }

    email_valid = (e, property) => {
        var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
        if (!re.test(e)) {
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }

    image_upload = (e) => {
        this.setState({ loader: true })

        if (e.target.files[0]) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            formData.append("upload_preset", "ml_default")
            formData.append("tags", "user_profile")

            Axios.post("https://api.cloudinary.com/v1_1/www-flipr-ai/image/upload/", formData)
                .then(res => {

                    this.props.onprofil_info_change('image', res.data.url)
                    this.setState({ loader: false })
                })
                .catch(err => err)
        }
    }

    linkedin_valid = (itemvalue) => {
        const re = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        if (!re.test(itemvalue)) {
            this.setState({ linkedinError: true })
        }
        else {
            this.setState({ linkedinError: false })
        }
    }

    errorHandler = (error, itemvalue) => {
        this.setState({ [error]: false })

        if (error === 'mobilenoError') {
            if (itemvalue.length === 10) {
                this.setState({ [error]: false })
            }
            else {
                this.setState({ [error]: true })
            }
        }
    }


    render() {

        return (
            <div>
                <p className="profile-info-header">Profile Information</p>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="image">Profile Picture</label>
                        <div className={classes.file_upload}>DB
                            {this.state.loader ?
                                <div className={['lds-spinner', classes.loader].join(' ')}>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div>
                                    <div className={classes.strip}></div></div>
                                : null}
                            <img className={classes.image} src={this.props.profile_info.image} />
                            <input className="file-btn file" type="file" id="image" accept="image/x-png,image/gif,image/jpeg"
                                onChange={(e) => this.image_upload(e)} />
                            <div className={classes.camera}>
                                <img className={classes.profile_camera} src={camera} />
                            </div>

                        </div>
                    </div>
                    <div className={[classes.password_group, 'form-group', 'col-md-6'].join(' ')}>
                        <label htmlFor="student_id">Student ID*</label>
                        <input type="text" className="form-control" value={this.props.profile_info.student_id} id="student_id"
                            onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)}
                            placeholder="Student ID" onKeyPress={(e) => this.onlyalphnumber(e)} onBlur={() => this.errorHandler('student_idError')} />
                        <p style={{
                            opacity: this.state.student_idError ? '1' : '0'
                        }} className="error">Enter only alphanumric</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6" style={{ paddingBottom: "0" }}>
                        <div className="form-row">
                            <div className="col-md-6 custom_firstname" >
                                <label htmlFor="firstname">First Name*</label>
                                <input type="text" className="form-control" id="firstname" value={this.props.profile_info.firstname}
                                    onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)} placeholder="First Name"
                                    onKeyPress={(e) => this.onlyalph(e, 'firstnameError')} onBlur={() => this.errorHandler('firstnameError')} />
                                <p style={{
                                    opacity: this.state.firstnameError ? '1' : '0'
                                }} className="error">Enter only alphabate</p>
                            </div>
                            <div className="col-md-6 custom_lastname" style={{ paddingTop: '1px' }}>
                                <label htmlFor="lastname">Last Name*</label>
                                <input type="text" className="form-control" id="lastname" value={this.props.profile_info.lastname}
                                    onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)}
                                    placeholder="Last Name*" onBlur={() => this.errorHandler('lastnameError')}
                                    onKeyPress={(e) => this.onlyalph(e, 'lastnameError')} />
                                <p style={{
                                    opacity: this.state.lastnameError ? '1' : '0'
                                }} className="error">Enter only alphabate</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="mobileno">Mobile*</label>
                        <input type="text" className="form-control" maxLength="10" id="mobileno" value={this.props.profile_info.mobileno}
                            onChange={(e) => { this.props.onprofil_info_change(e.target.id, e.target.value) }}
                            placeholder="Mobile*"
                            onBlur={(e) => this.errorHandler('mobilenoError', e.target.value)}
                            onKeyPress={(e) => this.onlynumber(e, 'mobilenoError')} />
                        <p style={{
                            opacity: this.state.mobilenoError ? '1' : '0'
                        }} className="error">Enter 10 digits</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="dob">DOB*</label>
                        <ModernDatepicker className="date-picker"
                            className="form-control"
                            date={this.props.profile_info.dob}
                            format={'DD-MM-YYYY'}
                            showBorder
                            placeholder="DOB"
                            id="dob"
                            onChange={(e) => this.props.onprofil_info_change('dob', e)}
                            placeholder={'Select Date'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Email*</label>
                        <input type="email" autoComplete="off" className="form-control" id="email" value={this.props.profile_info.email}
                            onChange={(e) => {
                                this.props.onprofil_info_change(e.target.id, e.target.value)
                                this.email_valid(e.target.value, 'emailError')
                            }} placeholder="Email" />
                        <p style={{
                            opacity: this.state.emailError ? '1' : '0'
                        }} className="error">Enter Valid Email address</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="gender">Gender</label>
                        <select className="form-control" id="gender" value={this.props.profile_info.gender}
                            onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)} >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="city">City</label>
                        <select className="form-control" id="city" value={this.props.profile_info.city}
                            onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)} >
                            <option value="">Select City</option>
                            <option value="Banglore">Banglore</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Kolkata">Kolkata</option>
                        </select>
                        <p className="error error-op">ERROR!</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="linkedin_profile">Linkedin Profile</label>
                        <input type="text" className="form-control" id="linkedin_profile" value={this.props.profile_info.linkedin_profile}
                            onChange={(e) => {
                                this.props.onprofil_info_change(e.target.id, e.target.value)
                                this.linkedin_valid(e.target.value)
                            }}
                            placeholder="http://www.linkedin.com/in/another-name" />
                        <p style={{
                            opacity: this.state.linkedinError ? '1' : '0'
                        }} className="error">Enter Valid linkedin profile</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="collegename">College*</label>
                        <input type="text" className="form-control" id="collegename" value={this.props.profile_info.collegename}
                            onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)} placeholder="College" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="shortbio">Short Bio / Carrier Objective</label>
                    <textarea type="text" className="form-control customtextarea" rows="5" id="shortbio" value={this.props.profile_info.shortbio}
                        onChange={(e) => this.props.onprofil_info_change(e.target.id, e.target.value)} placeholder="Short Bio" maxLength="250" />
                </div>
                <div className="custom_btn_div">
                    <button type="button" disabled style={{ opacity: '0' }} onClick={() => this.curentstateHandler('educ')} className="btn btn-primary btn-trans">Education</button>
                    <button type="button" onClick={() => this.curentstateHandler('educ')} className={['btn', 'btn-primary', 'btn-trans', 'btn-align', classes.fixed_btn_for].join(' ')}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Education
                                    </td>
                                    <td className="btn-arrow-for">
                                        &rarr;
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </button>
                </div>
            </div >
        );
    }
}



const mapStateToProps = state => {
    return {
        profile_info: state.profile_info_reducers.profile_info
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        onprofil_info_change: ((itemkey, itemvalue) => dispatch({ type: 'profil_info_change', value: { itemkey: itemkey, itemvalue: itemvalue } }))
    };
};




export default connect(mapStateToProps, mapDispatchToProps)(Profile_Information);