import React, { Component } from 'react';
import './student_form.css';
import { connect } from 'react-redux';
import Axios from 'axios';
import Sidebar from './sidebar/sidebar'
import Forms from './forms/forms';
import Progressbar from './progress_bar/progress_bar';
import { data_withvalidation } from './forms/form_validation';
import { firebase_database_base_url } from './../env/firebase '



class Student_form extends Component {


    componentDidMount() {
        this.form_update()
    }

    componentDidUpdate() {
        this.form_update()
    }


    resume_validation = () => {
        const exp_lenght = this.props.experience.length - 1
        console.log(this.props.experience)
        this.props.experience.map((experience, index) => {
            if (index === exp_lenght) {
                if (experience.role !== "" && experience.company_name !== "" &&
                    experience.department !== "" && experience.start !== "" &&
                    experience.end !== "") {
                    this.setState({ experience: true })
                }
                else {
                    this.setState({ experience: false })
                }
            }
            return 0
        })


        const extra_lenght = this.props.extra_curricular.length - 1
        this.props.extra_curricular.map((extra_curricular, index) => {
            if (index === extra_lenght) {
                if (extra_curricular.organization !== "" && extra_curricular.cause !== "") {
                    this.setState({ extra_curr: true })
                }
                else {
                    this.setState({ extra_curr: false })
                }
            }
        })
    }

    form_update = () => {

        const re = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        //profile info green dot validation start
        if (this.props.profile_info.student_id !== '' && this.props.profile_info.firstname !== '' &&
            this.props.profile_info.lastname !== '' && this.props.profile_info.mobileno !== '' && this.props.profile_info.city !== '' &&
            this.props.profile_info.dob !== '' && this.props.profile_info.email !== '' && this.props.profile_info.gender !== '' &&
            this.props.profile_info.collegename !== '' && this.props.profile_info.linkedin_profile !== '' && re.test(this.props.profile_info.linkedin_profile) &&
            this.props.profile_info.shortbio !== '' && this.props.profile_info.image !== '') { this.props.onsidebar_greendot(true, 'proinfo') }
        else {
            this.props.onsidebar_greendot(false, 'proinfo')
        }
        //profile info green dot validation end

        //experience green dot validation start
        const exp_lenght = this.props.experience.length - 1
        this.props.experience.map((experience, index) => {
            if (index === exp_lenght) {
                if (experience.role !== "" && experience.company_name !== "" &&
                    experience.department !== "" && experience.start !== "" &&
                    experience.end !== "") {
                    this.props.onsidebar_greendot(true, 'expe')
                }
                else {
                    this.props.onsidebar_greendot(false, 'expe')
                }
            }
            return 0
        })

        //experience green dot validation end

        //extra curricular green dot validation start

        if ((this.props.extra_curricular.length >= 2) || ((this.props.extra_curricular.length >= 1 &&
            (this.props.extra_curricular[0].cause !== ''
                && this.props.extra_curricular[0].organization !== '')))) {
            this.props.onsidebar_greendot(true, 'ex_curr')
        }
        else {
            this.props.onsidebar_greendot(false, 'ex_curr')

        }

        //extra curricular green dot validation end

        let languages = false
        for (let key in this.props.languages) {
            if (this.props.languages[key].read) {
                languages = true
                break;
            }
            else if (this.props.languages[key].write) {
                languages = true
                break;
            }
            else if (this.props.languages[key].speak) {
                languages = true
                break;
            }
        }

        if (languages) {
            this.props.onsidebar_greendot(true, 'language')
        }
        else {
            this.props.onsidebar_greendot(false, 'language')
        }



        if (this.props.skills.length > 0) {
            this.props.onsidebar_greendot(true, 'skills')
        }
        else {
            this.props.onsidebar_greendot(false, 'skills')

        }
        if (this.props.education.graduation.gr_education_type !== '' && this.props.education.graduation.gr_year_graduation !== '' &&
            this.props.education.graduation.gr_degree_program !== '' && this.props.education.graduation.gr_specialization !== '' &&
            this.props.education.graduation.gr_overall_percentage !== '' && this.props.education.graduation.gr_current_semster !== '' &&
            this.props.education.graduation.gr_do_uncleared_arreare !== '' && this.props.education.gr_no_uncleared_arreare !== '' &&

            this.props.education.tenth.te_education_type !== '' && this.props.education.tenth.te_year_completion !== '' &&
            this.props.education.tenth.te_board !== '' && this.props.education.tenth.te_overall_percentage !== '' &&
            this.props.education.tenth.te_math_score !== '' && this.props.education.tenth.te_physics_score !== '' &&

            this.props.education.twelfth.tw_education_type !== '' && this.props.education.twelfth.tw_year_completion !== '' &&
            this.props.education.twelfth.tw_board !== '' && this.props.education.twelfth.tw_overall_percentage !== '' &&
            this.props.education.twelfth.tw_math_score !== '' && this.props.education.twelfth.tw_physics_score !== '') {
            this.props.onsidebar_greendot(true, 'educ')
        }
        else {
            this.props.onsidebar_greendot(false, 'educ')
        }

    }



    submitdata = () => {
        this.props.onLoader(true)
        let data = {
            profile_info: this.props.profile_info,
            extra_curricular: [...this.props.extra_curricular],
            experience: [...this.props.experience],
            education: this.props.education,
            skills: [...this.props.skills],
            languages: [...this.props.languages]
        }
        Axios.patch(firebase_database_base_url + 'users/' + this.props.user_databaseId + '.json?auth=' + this.props.token, data_withvalidation(data))
            .then(res => {
                this.props.onLoader(false)
                this.props.onPop_up_message('User Data Saved')
            })
            .catch(err => this.props.onClosePop_up_message('Something Went Wrong'))
    }

    formsubmit = (e) => {
        e.preventDefault();
    }

    gotoresume = () => {

        let extra_curr = false
        let experience_valid = false
        const exp_lenght = this.props.experience.length - 1
        this.props.experience.map((experience, index) => {
            if (index === exp_lenght) {
                if (experience.role !== '' && experience.company_name !== '' &&
                    experience.department !== '' && experience.start !== '' &&
                    experience.end !== '') {
                    experience_valid = true
                }
                else {
                    experience_valid = false
                }
            }
        })


        const extra_lenght = this.props.extra_curricular.length - 1
        this.props.extra_curricular.map((extra_curricular, index) => {
            if (index === extra_lenght) {
                if (extra_curricular.organization !== "" && extra_curricular.cause !== "") {
                    extra_curr = true
                }
                else {
                    extra_curr = false
                }
            }
        })

        if (this.props.form_progress_counter < 95 || !extra_curr || !experience_valid) {
            if (!extra_curr) {
                this.props.onPop_up_message('Complete your profile upto 95% and required field of extra-curricular')
            }
            if (!experience_valid) {
                this.props.onPop_up_message('Complete your profile upto 95% and required field of experience')
            }

            if (!extra_curr && !experience_valid) {
                this.props.onPop_up_message('Complete your profile upto 95% and required field of experience and extra-curricular')
            }
            else if (this.props.form_progress_counter < 95) {
                this.props.onPop_up_message('Complete your profile upto 95%')
            }
        }
        else {
            this.props.history.push('/pdf_resume')

        }
    }


    render() {

        return (
            <div>

                <div className="profile-comp">
                    <div className="narrow profile-align">
                        <div className="profile-comp-div">
                            <span>Profile Completeness</span>
                            <Progressbar />
                            <span> {Math.round(this.props.form_progress_counter)} % Complete</span>
                        </div>

                        <div className="profile-comp-div1">
                            <button style={{ marginRight: '10px' }}
                                onClick={this.gotoresume}
                                className="btn btn-primary btn-trans btn-trans-findjobs">Resume</button>
                            <button className="btn btn-primary btn-submit" onClick={this.submitdata}>Save</button>
                        </div>

                    </div>
                </div>
                <div>
                    <form onSubmit={(e) => this.formsubmit(e)}>
                        <div className="content">
                            <Sidebar />
                            <Forms />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId,
        user_databaseId: state.common_reducer.user_databaseId,
        profile_info: state.profile_info_reducers.profile_info,
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item, //for componenedidupdate
        experience: state.experience_reducer.experience,
        experience_item: state.experience_reducer.experience_item, //for componenedidupdate
        education: state.education_reducer.education,
        skills: state.skills_reducer.skills,
        languages: state.languages_reducer.languages,
        form_progress_counter: state.common_reducer.form_progress_counter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onsidebar_greendot: ((status, formname) => dispatch({
            type: 'sidebar_greendot',
            value: { status: status, formname: formname }
        })),   //for green dot updation
        onPop_up_message: ((pop_up_message) => dispatch({
            type: 'pop_up_message', value: {
                pop_up_message: pop_up_message
            }
        })),
        onLoader: ((loader) => dispatch({
            type: 'loader', value: {
                loader: loader
            }
        })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Student_form);